import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react"
import { Link, navigate } from "gatsby"
import React, {
  Component,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query"
import {
  chipButton,
  down,
  right,
  saveButton,
  saveButtonOrange,
  underlineButton,
} from "../Styling/Buttons"
import { MyDonorAgreementAmount } from "./MyDonorAgreementAmount"
import LoadingModal from "../Modal/LoadingModal"
import axios from "axios"
import { authToken } from "../Security/authToken"
import { element } from "prop-types"
import { GetSubscriptions } from "../Utils/ReactQueries"
import { patchApi } from "../Utils/WebApi-utils"
import { toast, ToastContainer } from "react-toastify"
import { inputFieldBlue, inputFieldErrorAmount } from "../Styling/InputField"

export type ActiveAgreementProps = {
  subscriptionNumber: string
  amount: number
  newAmount: number
  isCancellation: boolean
  provider: string
  organization: string
  frequency: string
  chipButtonClass: string
  customAmount: boolean
}

export const MyActiveDonorAgreement = () => {
  const { instance, accounts, inProgress } = useMsal()
  const queryClient = useQueryClient()
  const [loading, setLoading] = useState(true)
  const [donorArray, setDonorArray] = useState<any>("")
  const [hasDonorAgreement, setHasDonorAgreement] = useState(false)
  const [inputAmount, setInputAmount] = useState(0)
  const [button1Price, setButtonButton1Price] = useState("")
  const [button2Price, setButtonButton2Price] = useState("")
  const [price1, setPrice1] = useState(0)
  const [price2, setPrice2] = useState(0)
  const [cancel, setCancel] = useState(false)
  const [showError, setShowError] = useState(false)
  const [loadingError, setLoadingError] = useState(false)
  const [agreementCanceled, setAgreementCanceled] = useState(false)
  const [subscriptionNumber, setSubscriptionNumber] = useState("")
  const [amount, setAmount] = useState(0)
  const [newAmount, setNewAmount] = useState(0)
  const [isCancellation, setIsCancellation] = useState(false)
  const [provider, setProvider] = useState("")
  const [organization, setOrganization] = useState("")
  const [frequency, setFrequency] = useState("")
  const [chipButtonClass, setChipButtonClass] = useState("")
  const [customAmount, setCustomAmount] = useState(false)
  const [props, setProps] = useState<ActiveAgreementProps>({
    subscriptionNumber: "",
    amount: 0,
    newAmount: 0,
    isCancellation: false,
    provider: "Vipps",
    organization: "HEF",
    frequency: "",
    chipButtonClass: "",
    customAmount: false,
  })

  const MySubs = GetSubscriptions(accounts, inProgress, instance)

  // Update the agreement
  const AgreementUpdated = useMutation(
    async values =>
      patchApi(
        process.env.GATSBY_APP_WEBAPI + "HefPayment/Agreement",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        toast.success("Giveravtalen ble oppdatert", {
          bodyClassName: "bg-blue text-white",
          theme: "colored",
          style: { backgroundColor: "#004C97" },
        })
        MySubs.refetch()
        setLoading(false)
      },
      onError: data => {
        console.log(data)
        console.log("Error")
        setLoading(false)
        toast.error("Noe gikk galt")
      },
      retry: (count, error) => {
        if (count >= 1) {
          setLoadingError(true)
          setLoading(false)
          return false
        }
        return true
      },
    }
  )

  // Cancel the agreement
  const AgreementCanceled = useMutation(
    async values =>
      patchApi(
        process.env.GATSBY_APP_WEBAPI + "HefPayment/Agreement",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        console.log(data)
        toast.success("Giveravtalen ble avsluttet", {
          bodyClassName: "bg-blue text-white",
          theme: "colored",
          style: { backgroundColor: "#004C97" },
        })
        queryClient.invalidateQueries("getSubscription")
        queryClient.invalidateQueries("getIncomingSubscription")
        setDonorArray("")
        setLoading(false)
      },
      onError: data => {
        console.log(data)
        console.log("Error")
        setLoading(false)
        toast.error("Noe gikk galt")
      },
      retry: (count, error) => {
        if (count >= 1) {
          setLoadingError(true)
          return false
        }
        return true
      },
    }
  )

  //If MySubs is undefined after refecthing/reloading the page
  useEffect(() => {
    if (MySubs.isSuccess && MySubs?.data == undefined) {
      MySubs.refetch()
    } else if (MySubs.isSuccess && MySubs?.data != undefined) {
      setLoading(false)
    }
  }, [MySubs.isSuccess, MySubs.isRefetching])

  //If MySubs is success and there is a product with the name "Giveravtale" in it. Make an element and update props with values.
  useEffect(() => {
    if (MySubs.isSuccess && MySubs.isRefetching == false) {
      MySubs?.data?.forEach(element => {
        if (element?.productId?.name == "Giveravtale") {
          setHasDonorAgreement(true)
          setDonorArray(element)
          setProps({
            ...props,
            amount: element?.amount,
            newAmount: element?.amount,
            subscriptionNumber: element?.subscriptionNumber,
            frequency: element?.paymentFrequency,
          })
        }
      })
    }
  }, [
    MySubs.isSuccess,
    MySubs.isRefetching,
    AgreementUpdated.isSuccess,
    AgreementCanceled.isSuccess,
  ])

  //The object with updated values.
  const updateAgreement = () => {
    if (props.newAmount >= 25) {
      const updatedObject: any = {
        subscriptionNumber: props.subscriptionNumber,
        price: props.newAmount,
        provider: "Vipps",
        organization: "HEF",
      }
      console.log(updatedObject)
      setLoading(true)
      AgreementUpdated.mutate(updatedObject)
      setShowError(false)
    } else {
      console.log("Verdi er for lav")
      setShowError(true)
    }
  }

  //The object with the right values to cancel an agreement
  const cancelAgreement = () => {
    const cancelObject: any = {
      subscriptionNumber: props.subscriptionNumber,
      isCancellation: true,
      provider: "Vipps",
      organization: "HEF",
    }
    console.log(cancelObject)
    setLoading(true)
    AgreementCanceled.mutate(cancelObject)
  }

  //Make frequency to readable string
  if (props.frequency === "778380000") {
    setProps({ ...props, frequency: "månedlig" })
  } else if (props.frequency === "778380002") {
    setProps({ ...props, frequency: "halvåret" })
  } else if (props.frequency === "778380003") {
    setProps({ ...props, frequency: "året" })
  }

  console.log(props.frequency)

  //Prices depending on frequency
  useEffect(() => {
    if (props.frequency === "året") {
      setButtonButton1Price("350,-")
      setButtonButton2Price("1000,-")
      setPrice1(350)
      setPrice2(1000)
    } else if (props.frequency === "halvåret") {
      setButtonButton1Price("100,-")
      setButtonButton2Price("350,-")
      setPrice1(100)
      setPrice2(350)
    } else if (props.frequency === "månedlig") {
      setButtonButton1Price("75,-")
      setButtonButton2Price("150,-")
      setPrice1(75)
      setPrice2(150)
    } else {
      setButtonButton1Price("")
      setButtonButton2Price("")
      setPrice1(0)
      setPrice2(0)
    }
  }, [props, setProps])

  console.log(donorArray)
  console.log(MySubs)
  console.log(props)

  console.log(hasDonorAgreement)

  return (
    <div>
      {/* <ToastContainer /> */}
      {loading ? (
        <LoadingModal />
      ) : (
        <div className="bg-gray px-8 py-8 pb-16  lg:flex lg:justify-center lg:items-center">
          <div className="lg:min-w-[50%] lg:max-w-[50%]">
            <div className="flex flex-col">
              <div>
                <h1 className="font-sans text-blue text-xl font-normal">
                  Administrer giveravtalen
                </h1>
              </div>
              {hasDonorAgreement && donorArray != "" ? (
                <div>
                  <div className="mb-8 mt-2">
                    <p className="font-sans">
                      Du donerer{" "}
                      <span className=" font-sans font-bold ">
                        {props.amount},-/{props.frequency}{" "}
                      </span>
                      til Human-Etisk Forbund
                    </p>
                    <p className="font-sans mb-0">
                      Det er ingen bindingstid og dersom du ønsker å stoppe
                      avtalen om fast trekk, kan du gjøre dette ved å trykke på
                      "Avslutt avtalen" eller sende mail til{" "}
                      <a
                        className="font-sans text-blue hover:text-orange-dark"
                        href="mailto:medlem@human.no"
                      >
                        medlem@human.no
                      </a>
                    </p>
                  </div>
                  <div className="mb-2">
                    <label className="font-sans text-blue">Beløp</label>
                  </div>
                  <div className="mb-12">
                    <div className="flex flex-wrap space-x-4 mb-4">
                      <button
                        className={
                          props.chipButtonClass === "1" ||
                          props.newAmount == price1
                            ? " bg-blue border-2 border-blue p-1 px-3 pt-1  font-sans text-white "
                            : chipButton + down
                        }
                        onClick={() => {
                          setShowError(false)
                          setProps({
                            ...props,
                            newAmount: price1,
                            customAmount: false,
                            chipButtonClass: "1",
                          })
                        }}
                      >
                        {button1Price}
                      </button>
                      <button
                        className={
                          props.chipButtonClass === "2" ||
                          props.newAmount == price2
                            ? " bg-blue border-2 border-blue p-1 px-3 pt-1 font-sans text-white "
                            : chipButton + down
                        }
                        onClick={() => {
                          setShowError(false)
                          setProps({
                            ...props,
                            newAmount: price2,
                            customAmount: false,
                            chipButtonClass: "2",
                          })
                        }}
                      >
                        {button2Price}
                      </button>
                      <button
                        className={
                          props.chipButtonClass === "custom"
                            ? " bg-blue border-2 border-blue p-1 px-3 pt-1  mr-4  font-sans text-white "
                            : chipButton + down
                        }
                        onClick={() => {
                          setProps({
                            ...props,
                            customAmount: true,
                            newAmount: 0,
                            chipButtonClass: "custom",
                          })
                        }}
                      >
                        Valgfritt
                      </button>
                    </div>
                    {props.customAmount ? (
                      <div>
                        <input
                          type="number"
                          className={
                            props.newAmount < 25
                              ? inputFieldErrorAmount
                              : inputFieldBlue
                          }
                          placeholder="Skriv et ønsket beløp"
                          onChange={e => {
                            setInputAmount(e.target.valueAsNumber)
                            setProps({
                              ...props,
                              newAmount: e.target.valueAsNumber,
                            })
                          }}
                        ></input>
                        {props.newAmount < 25 ? (
                          <div className="mt-2">
                            <p className="font-sans text-orange-dark">
                              Beløpet må være minst 25,-
                            </p>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  {cancel ? (
                    <div className=" bg-white pt-4 pb-2 px-4">
                      <p className="font-sans font-bold">
                        Er du sikker på at du vil avslutte avtalen?
                      </p>
                      <button
                        className={saveButtonOrange + down}
                        onClick={cancelAgreement}
                      >
                        Avslutt avtalen
                      </button>
                      <button
                        className={underlineButton}
                        onClick={() => setCancel(false)}
                      >
                        Fortsett som giver
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        onClick={updateAgreement}
                        className={saveButton + down}
                      >
                        Oppdater avtalen
                      </button>
                      <button
                        onClick={() => setCancel(true)}
                        className={underlineButton + " text-orange-dark "}
                      >
                        Avslutt avtalen
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <p className="mt-2 font-sans mb-2">
                    Du har ingen giveravtaler
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
